import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"
import Layout from "components/Layout"
import { Section as Sec } from "."
import colors from "styles/colors"
import { RichText } from "prismic-reactjs"

const PageTitle = styled("h1")`
  margin-bottom: 1em;
`

const MusicTitle = styled("h2")`
  margin: 0.2em 0em;
  color: ${colors.orange500};
`

const Section = styled(Sec)`
  margin-bottom: 2em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-bottom: 0em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    margin-bottom: 0em;
  }
`

const MusicDescription = styled("div")`
  margin: 0 auto;

  .block-img {
    margin-top: 3.5em;
    margin-bottom: 0.5em;

    img {
      width: 100%;
    }
  }

  a {
    /* text-decoration: none; */
    transition: all 100ms ease-in-out;

    &:nth-of-type(1) {
      color: ${colors.orange500};
    }

    &:hover {
      cursor: pointer;
      transition: all 100ms ease-in-out;

      &:nth-of-type(1) {
        color: ${colors.orange500};
        background-color: ${colors.orange200};
      }
    }
  }

  .embed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 0 2em 0;
  }
`

const MusicSubtitle = styled("h3")`
  margin: 0.5em 0em;
  margin-bottom: 1em;
  font-weight: 300;
  font-family: sans-serif;
  color: ${colors.grey500};
`

const Music = ({ music, meta }) => (
  <>
    <Helmet
      title={`💿 music | dhruv c`}
      titleTemplate={`%s | music | dhruv c`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: `music | dhruv c`,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <Layout>
      <PageTitle>Music</PageTitle>
      <Section>
        {music.music_title && (
          <MusicTitle>{music.music_title[0].text}</MusicTitle>
        )}
        {music.music_subtitle && (
          <MusicSubtitle>{music.music_subtitle[0].text}</MusicSubtitle>
        )}
      </Section>
      <Section>
        <MusicDescription>
          {RichText.render(music.music_description)}
        </MusicDescription>
      </Section>
    </Layout>
  </>
)

export default ({ data }) => {
  const music = data.prismic.allMusics.edges.slice(0, 1).pop()
  const meta = data.site.siteMetadata
  if (!music) return null

  return <Music music={music.node} meta={meta} />
}

Music.propTypes = {
  music: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    prismic {
      allMusics {
        edges {
          node {
            music_title
            music_subtitle
            music_description
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
